.address-location-item {
  iframe {
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
    display: block;
    height: 200px;
  }
  & > div {
    padding: 50px 0;
    text-align: center;
    max-width: 250px;
    margin: 0 auto;
    a {
      display: block;
      color: gray;
      margin: 5px 0;
      text-decoration: none;
      font-weight: 600;
    }
    p {
      span,
      strong,
      a {
        display: block;
      }
    }
  }
}
