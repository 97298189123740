@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  background-color: #f0ebe8;
  strong,
  b {
    color: #ba8b4a;
  }

  .black-text {
    color: #3b1c0c;
    strong,
    b {
      color: #3b1c0c;
    }
  }

  h1 {
    font-size: 4.37em;
  }
  h2 {
    font-size: 2.2em;
    color: #a58d69;
    font-weight: 300;
    text-transform: uppercase;
    padding-bottom: 40px;
    &.lined {
      display: inline-block;
      position: relative;
      overflow: hidden;
      &::after {
        position: absolute;
        width: 500px;
        right: 0;
        bottom: 20px;
        height: 1px;
        content: "";
        display: block;
        background-color: #a58d69;
      }
    }
  }
  h3 {
    font-size: 2em;
  }
  h4 {
    font-size: 1.375em;
    color: #8d9a8d;
  }
}
p {
  text-align: justify;
}
.btn {
  padding: 13px;
  font-size: 1.12em;
  border-radius: 0;
  &.btn-outline-dark {
    border: 1px solid #82a482;
    color: #52555d;
    &:hover {
      color: white;
    }
  }
  &.btn-outline-light {
    border: 1px solid #ffffff;
    color: #ffffff;
  }
}

.text-right {
  text-align: right;
}

section {
  @media screen and (min-width: 679px) {
    margin-bottom: 80px;
    min-height: 90vh;
  }
}

.wp-block {
  &-media-text {
    & > figure {
      img {
        max-width: 100%;
      }
    }
    @media screen and (min-width: 800px) {
      display: flex;
      align-items: flex-start;
      & > figure {
        flex: 1;
      }
      &__content {
        flex: 1;
        padding-left: 40px;
      }
    }
  }
  &-columns {
    display: flex;
    & > .wp-block-column {
      margin-right: 15px;
    }
  }
  &-quote {
    margin-bottom: 20px;
    max-width: 400px;
    padding-left: 80px;
    // background: transparent url("./assets/images/quot.png") no-repeat left top;
    cite {
      font-style: italic;
      font-size: 2em;
      font-weight: bold;
      line-height: 1em;
    }
  }
}

@media (max-width: 678px) {
  .container {
    padding: 0 20px !important;
  }
}
