@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
*{box-sizing:border-box;margin:0;padding:0}body{font-size:16px;font-family:"Montserrat",sans-serif;background-color:#f0ebe8}body strong,body b{color:#ba8b4a}body .black-text{color:#3b1c0c}body .black-text strong,body .black-text b{color:#3b1c0c}body h1{font-size:4.37em}body h2{font-size:2.2em;color:#a58d69;font-weight:300;text-transform:uppercase;padding-bottom:40px}body h2.lined{display:inline-block;position:relative;overflow:hidden}body h2.lined::after{position:absolute;width:500px;right:0;bottom:20px;height:1px;content:"";display:block;background-color:#a58d69}body h3{font-size:2em}body h4{font-size:1.375em;color:#8d9a8d}p{text-align:justify}.btn{padding:13px;font-size:1.12em;border-radius:0}.btn.btn-outline-dark{border:1px solid #82a482;color:#52555d}.btn.btn-outline-dark:hover{color:#fff}.btn.btn-outline-light{border:1px solid #fff;color:#fff}.text-right{text-align:right}@media screen and (min-width: 679px){section{margin-bottom:80px;min-height:90vh}}.wp-block-media-text>figure img{max-width:100%}@media screen and (min-width: 800px){.wp-block-media-text{display:flex;align-items:flex-start}.wp-block-media-text>figure{flex:1 1}.wp-block-media-text__content{flex:1 1;padding-left:40px}}.wp-block-columns{display:flex}.wp-block-columns>.wp-block-column{margin-right:15px}.wp-block-quote{margin-bottom:20px;max-width:400px;padding-left:80px}.wp-block-quote cite{font-style:italic;font-size:2em;font-weight:bold;line-height:1em}@media(max-width: 678px){.container{padding:0 20px !important}}
.About{padding:250px 0 63px 0;position:relative}.About .background{position:absolute;left:0;top:0;width:100%;text-align:right;z-index:0}.About .background img{width:100%;max-width:1400px}@media screen and (max-width: 678px){.About{padding-top:300px}.About .background img{width:auto;height:300px}}.About .content{position:relative;z-index:10}.About h4:first-child{color:#8d9a8d;text-transform:uppercase;margin-top:154px}.About h3{line-height:80%;font-size:3.298em;margin-top:16px;padding:32px;border:1px solid #e2e2e2;box-sizing:border-box}
.acting-item{cursor:pointer;margin-bottom:30px}.acting-item h4{background-color:#d7d1c9;text-align:center;text-transform:uppercase;color:#000;padding:5px 8px;margin:0 auto;font-weight:300}.acting-item>div{margin-top:0;padding:0;border:1px #d7d1c9 solid;max-height:0;opacity:0;text-align:justify;transition:all .5s ease-in-out;pointer-events:none}.acting-item:hover>div,.acting-item:active>div,.acting-item.active>div{margin-top:15px;max-height:100%;padding:15px;opacity:1}
.Acting{padding:63px 0 20px}.Acting .header-acting{margin-bottom:50px;background-color:#d1ccc0;padding:30px}.Acting .header-acting .call{color:#463b22;max-width:90%}.Acting .header-acting a{background-color:#fff;color:#a28e65;border:0;min-width:150px;box-shadow:0 0 15px rgba(0,0,0,.5)}.Acting .header-acting .contact-button{text-align:left}@media screen and (min-width: 768px){.Acting .header-acting .contact-button{text-align:right}}.Acting .actings h2{color:#000}.Acting .actings h3{text-transform:uppercase;text-align:center}.Acting .actings h3 span{display:block;height:5px}.Acting .actings h4{text-align:left;font-weight:500;padding:8px}@media screen and (min-width: 768px){.Acting .actings h3{font-weight:700;font-size:2.5em;display:flex;flex-wrap:wrap;justify-content:center;align-items:center}.Acting .actings h3 span{width:4px;height:30px;margin:0 20px;background-color:#d1ccc0}.Acting .actings h4{font-size:1.8em}}.Acting .cabecalho .infoname-md{border-bottom:1px gray solid}.Acting .cabecalho .acting-item{max-width:90%;margin:30px auto 0}@media screen and (min-width: 768px){.Acting .cabecalho .infoname-md{display:block}.Acting .cabecalho .infoname{display:none}}
.address-location-item iframe{width:100%;max-width:250px;margin:0 auto;display:block;height:200px}.address-location-item>div{padding:50px 0;text-align:center;max-width:250px;margin:0 auto}.address-location-item>div a{display:block;color:gray;margin:5px 0;text-decoration:none;font-weight:600}.address-location-item>div p span,.address-location-item>div p strong,.address-location-item>div p a{display:block}
.AddressLocation{min-height:auto}
.Contact{padding:78px 0;border-top:1px gray solid;margin-top:50px;position:relative}.Contact input[type=phone]{border:0 none;padding:10px;border-radius:8px;box-shadow:0 0 10px rgba(0,0,0,.6)}.Contact::after{content:"";display:block;position:absolute;bottom:78px;left:0;width:45%;border-bottom:1px gray solid}@media screen and (max-width: 899px){.Contact{padding:78px 50px}}.Contact small{color:#eb5b5b;display:block;min-height:25px;width:100%}.Contact .call{max-width:300px;margin-bottom:30px}.Contact a{color:#a48d68;text-decoration:none;font-weight:600}.Contact .phone-numbers p{margin:5px 0}.Contact .Contact-wrapper{margin:0 auto}.Contact .Contact-wrapper h2{font-weight:400;margin-bottom:30px}@media screen and (max-width: 499px){.Contact .Contact-wrapper h2{font-size:48px}}.Contact .Contact-wrapper .form-control{box-shadow:0 0 10px gray}.Contact .Contact-wrapper form.principal-fields input:not(input[type=checkbox]){height:48px;width:100%;margin-bottom:15px}.Contact .Contact-wrapper form.principal-fields .text-right{text-align:right}.Contact .Contact-wrapper form.principal-fields button{height:48px;padding:0 35px;font-size:1.1em;line-height:.5em}.Contact .Contact-wrapper form.principal-fields button.btn-success{background-color:#7cb87d;text-transform:uppercase}@media screen and (max-width: 899px){.Contact .Contact-wrapper form.principal-fields{align-items:flex-end}.Contact .Contact-wrapper form.principal-fields{flex-direction:column}.Contact .Contact-wrapper form.principal-fields>div{width:100%;margin-bottom:15px;margin-right:0 !important}.Contact .Contact-wrapper form.principal-fields button{margin-left:0;width:50%}}@media screen and (max-width: 600px){.Contact .Contact-wrapper form.principal-fields button{width:100%}}
.socialButtons{display:flex;align-items:center;justify-content:center;flex-wrap:wrap}.socialButtons a{margin-right:16px;font-size:54px;line-height:1;color:#a48d68}.socialButtons a:last-child{margin-right:0}
footer{background:#d7d1c9;padding:40px 0}footer img{max-width:100%;margin:0 auto}@media screen and (max-width: 768px){footer .socialButtons a{font-size:40px}footer img{max-width:70%;margin-bottom:15px}footer .footerInfo{text-align:center}}
.header{background:rgba(0,0,0,0);position:fixed !important;width:100%;z-index:40;padding:30px 0;top:0;left:0;transition:all .3s ease-in-out}.header .language-switcher{display:flex;flex-wrap:nowrap}.header .language-switcher,.header .language-switcher a{font-size:.8rem;color:#585044;display:inline-block;text-shadow:2px 2px 2 #323232}.header .language-switcher a{cursor:pointer;text-decoration:none;transition:all .3s ease-in-out}.header .language-switcher a:hover,.header .language-switcher a.active{text-decoration:underline;font-weight:500}@media screen and (max-width: 991px){.header .container{margin:0;padding:0}}.header .title{color:#52555d;text-decoration:none;font-size:38px}.header .title img{width:100%;max-width:370px;transition:all .3s ease-in-out}@media screen and (max-width: 991px){.header .title{font-size:20px}}@media screen and (max-width: 678px){.header{padding:5px;background:rgba(255,255,255,.95)}.header .title img{max-width:200px}}@media screen and (min-width: 679px){.header.over{padding:10px 0;background:rgba(255,255,255,.9)}.header.over .title img{max-width:320px}.header.over .navbar-collapse.show{background-color:rgba(255,255,255,.9)}}.header .navbar-brand{padding-right:64px}@media screen and (max-width: 991px){.header .navbar-brand{padding-right:0;padding-left:15px}}.header .navbar-collapse{justify-content:flex-end}.header .nav-link{color:#323232;padding:0}@media screen and (max-width: 991px){.header .nav-link{padding:15px}.header .nav-link:not(:last-child){border-bottom:1px #ccc solid}}.header .nav-link::after{content:"";width:10px;margin:0 20px;color:#323232;border:#8d9a8d solid 1px}@media screen and (max-width: 991px){.header .nav-link::after{margin:0;border:0 none}}.header .nav-link:last-child::after{border:none}
.team-member{text-align:center;color:#483923;margin-bottom:30px}.team-member img{width:70%}.team-member .description{display:none;width:100%;left:0;position:absolute;padding:30px;border:1px #473b23 solid;text-align:justify}@media screen and (max-width: 768px){.team-member .description{width:90%;left:5%}}.team-member h3{display:flex;height:100px;padding:0 30px;font-size:1.6em;font-weight:300;justify-content:center;align-items:center;margin-bottom:30px}.team-member.visible{padding-bottom:150px}.team-member.visible h3{color:#473b23;background-color:#d8d1c9;position:relative}.team-member.visible h3::after{content:"";display:block;position:absolute;width:2px;height:30px;left:50%;margin-left:-1px;bottom:-30px;background-color:#d8d1c9}@media screen and (max-width: 768px){.team-member.visible{padding-bottom:450px}}.team-member.visible .description{display:block}
.Team{background:rgba(0,0,0,0) url(/wp-content/themes/dalazen/static/media/team.cac764b6.png) no-repeat bottom center}.Team .area-call{padding:20px 0;color:#fff;font-size:2em;margin-bottom:80px;background:#a58d69 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFwAAABhBAMAAACpJdsYAAAAIVBMVEUAAADn4dvl39rl3dbb1NTBwcHe18nn3tjj39nj2tXe3tPTkj2hAAAAC3RSTlMAKiQWCwQHHhoSD84kf1EAAAOzSURBVFjDpVjPTxNBGN3YbWW5zZRkW07O2hb11KVRCydoAqSeViQCt6ZgTDixYD1TSNQjlUQTT0D8Efwrnd228+bHMt3E70J29vHmzfu+eUtw/quKh3et1o93OdE7LxnhRV/kQr85IeOin3KgvTiB+hftmNDLmehSmKK/Oc7ROSnPhL8nhCtvOrzcmP+0l8sSdHX8UJhJ30nQgvSWXNuVx5RS4keTx3lSt8L3KOPsz8Tz0K7maxAEVKLcppGt+3HAGKQnh7VZ73FyxijOV2KPLPCHNODktIeVsGGzMWX3Jb03VQt8OZUuwzuV6P6TnnNfVPY9izWlk0DXXsCDCedoXnRNgpP74S4/KUlHBtZSK5zxppLjPGLAXssPp5y9gqU53+IM48YkhbP2y44NnrKTAbq64FjaNGEHZVi3DQENaAIXzhdZ0zZiHJzy1y3GoHYpU9VsWy9fgesgsjdhzZ4yLMkZ4Y1L7LE3TNihpu/PSKUELbwpsYYdXkixUzV9a4ilfFN4OXmoOjPqhhs/9aYzM4CdOaHm2AN5DjWVEPFkUyMKLZqtBnGTV40VBzXoVH41mOL8ncpTHUGfS00BavKy51ezzwQ8R1vdmLbzqykNSS2zUztXX1bMoy+TcuSsm94cJFm7pIvbIv5aljcH4w0fR/oxB+nXWPPGncprasIrmZ0aZc70LSED/YbjUZ/SgvjoQU1FISdUEh8KraqaeQJ4T5mVaQx5spo+Of1uTl0on1zypsjKkWewF5Rf7kNN8jeNC+0Sud9zDDXHr6vc4lhzxtMmdii8ic9wgxvKlHMeqEGA4AYncQ+nFwGHeylHcSh2x9sFR1MDjMfkKNkFXFUjQN32xW/xckQgRlGDKS5G+tZKQodQg8JA4wX0wQ+lCvAMFALeMIIRI2GqMbP44eTFtboINdnwMz1cs9VsZ35fRlCTDS+be0Ik6gHJOqtrqgEPxCMaoMY0ElNjTnFTcqCHy2M4b6oZ1STPBsqUsYyAiRe16YaWlqD/iHlpyEH1XGoTjQQN/TUZ45DU5fGrCPUdvlUH6xspeotngxJsT6e7JuntYZ2ubh52z8cuFWMsn6b8BzG/dvI6oUEQTC/jOkE9Wfl712bJXYT1KF/NcNQl4gyVksBKVFVfx5zAG/UjDDVK30Vqoj6oAaJP1R6WlI/iSNsTn2Cp/MQuEXeoGiaKGSQIZ/Rc1H4snN9wUMU/yp6oo/E/Q5ZWUzTw3c9s3G913Sl1W63W6qaj19tXV/zFz8np/wF8Pdka6mDdIQAAAABJRU5ErkJggg==) no-repeat 95% 80%}.Team .area-call strong{color:#fff}.Team .area-call h2{color:#fff;padding:0}.Team .group-members{position:relative}
.loader-element{display:flex;width:100vw;height:100vh;align-items:center;flex-direction:column;justify-content:center}
.Office{padding:63px 0 0;min-height:auto}.Office h4:first-child{color:#8d9a8d;text-transform:uppercase;margin-top:154px}.Office h3{line-height:80%;font-size:3.298em;margin-top:16px;padding:32px;border:1px solid #e2e2e2;box-sizing:border-box}

