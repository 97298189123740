footer {
  background: #d7d1c9;
  padding: 40px 0;
  img {
    max-width: 100%;
    margin: 0 auto;
  }
  @media screen and (max-width: 768px) {
    .socialButtons a {
      font-size: 40px;
    }
    img {
      max-width: 70%;
      margin-bottom: 15px;
    }
    .footerInfo {
      text-align: center;
    }
  }
}
