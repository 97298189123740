.Acting {
  padding: 63px 0 20px;
  .header-acting {
    margin-bottom: 50px;
    background-color: #d1ccc0;
    padding: 30px;
    .call {
      color: #463b22;
      max-width: 90%;
    }
    a {
      background-color: white;
      color: #a28e65;
      border: 0;
      min-width: 150px;
      box-shadow: 0 0 15px rgba($color: #000000, $alpha: 0.5);
    }
    .contact-button {
      text-align: left;
      @media screen and (min-width: 768px) {
        text-align: right;
      }
    }
  }
  .actings {
    h2 {
      color: black;
    }
    h3 {
      text-transform: uppercase;
      text-align: center;
      span {
        display: block;
        height: 5px;
      }
    }
    h4 {
      text-align: left;
      font-weight: 500;
      padding: 8px;
    }
    @media screen and (min-width: 768px) {
      h3 {
        font-weight: 700;
        font-size: 2.5em;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        span {
          width: 4px;
          height: 30px;
          margin: 0 20px;
          background-color: #d1ccc0;
        }
      }
      h4 {
        font-size: 1.8em;
      }
    }
  }
  .cabecalho {
    .infoname-md {
      border-bottom: 1px gray solid;
      // display: none;
    }
    .acting-item {
      max-width: 90%;
      margin: 30px auto 0;
    }
    @media screen and (min-width: 768px) {
      .infoname-md {
        display: block;
      }
      .infoname {
        display: none;
      }
    }
  }
}
