.Office {
  padding: 63px 0 0;
  min-height: auto;

  h4:first-child {
    color: #8d9a8d;
    text-transform: uppercase;
    margin-top: 154px;
  }
  h3 {
    line-height: 80%;
    font-size: 3.298em;
    margin-top: 16px;
    padding: 32px;
    border: 1px solid #e2e2e2;
    box-sizing: border-box;
  }
}
